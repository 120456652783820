.flight-options {
    max-height: 35vh;
    overflow-y: auto;
}

.individual-flight-option, .individual-flight-option-expand {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.individual-flight-option div {
    min-width: 7em;
    margin: 0 auto;
}

.individual-flight-option-expand div {
    margin-right: 3em;
}

/* .accordion-button {
    pointer-events: none;
}

.accordion-button::after {
    pointer-events: all;
} */
