.home {
    /* background: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url("../assets/background.jpg") no-repeat center center fixed; */
    background: url("../assets/bg.jpg") no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;
    overflow: hidden;
}

.landing-callout {
    padding: 0.25em 0.5em 0.3em 0.5em;
    background-color: transparent;
    /* Transparent background */
    border: 1px solid black;
    /* Black border */
    border-radius: 16px;
    /* Rounded edges */
}

.landing-callout:hover {
    border: 1px solid darkgray;
}

.callout-learn-more {
    font-weight: bold;
    color: darkblue;
}

.callout-learn-more:hover {
    font-weight: bold;
    color: blue;
}

.landing-text {
    color: black;
    text-align: center;
}

.navbar-no-bg {
    background: none;
    padding-top: 32px;
}

.navbar-margin {
    margin-right: 24px;
}

.h1-name {
    margin-top: 5%;
    font-size: 3.75vw;
}

.h1-action {
    margin-top: 5%;
    font-size: 3.5em;
}

.slogan {
    margin-top: 5%;
    font-size: 2vw;
}

.h2-slogan {
    font-size: 1.5vw;
}

.log-in-button {
    padding: 8px 16px;
}

.get-started-button {
    margin-top: 32px;
    padding: 8px 16px;
}

.no-style-link {
    color: black;
    text-decoration: none;
}

.no-style-link-light {
    color: white;
    text-decoration: none;
}

footer {
    width: 100%;
    background-color: black;
    padding: 8px 8px 8px 8px;
    color: #fff;
}