.admin-dashboard-landing {
    padding-top: 5vh;
}

.card--scroll {
  display: flex;
  min-width: 100%;
  min-height: 200px;
  overflow-x: auto;
}

.card--content {
    min-width: 18rem;
    max-width: 18rem;
    margin-right: 32px;
}

.dark-rect-round {
  background-color: rgba(0,0,0,0.2);
  border-radius: 16px;
  padding: 16px;
  margin: 32px;
}

.attendee-table {
  max-height: 55vh;
  overflow-y: auto;
}

#payment-message {
  color: red;
}
