.grey-rect-round {
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 16px;
    padding: 16px;
    margin: 32px;
}

.grey-rect-no-padding {
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 16px;
}

.blue-rect-round {
    background-color: rgba(173, 216, 230, 0.75);
    border-radius: 16px;
    padding: 32px;
    margin: 32px;
}

.text-align-center {
    text-align: center;
    color: black !important;
}

.sign-in-button {
    margin-top: 16px;
    padding: 8px 16px;
}